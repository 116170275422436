
import { globalVar } from '~/utils/globalVar.js'
export default {
    name: 'ThemeMode',
    data: () => ({
        openThemeBtn: false,
        globalVar,
    }),
    computed: {
        themeValue() {
            return this.$cookie.get('theme')
        },
    },
    methods: {
        changeTheme(themeType) {
            if (this.themeValue === themeType) return
            this.$cookie.set('theme', themeType)
            this.$router.go(this.$route.path)
        },
    },
}
