
import { mapState } from 'vuex'
import GlobalMixins from '~/mixins/globalMixins'
export default {
    name: 'Theme3SwitchLanguages',
    mixins: [GlobalMixins],
    data() {
        return {}
    },
    computed: {
        ...mapState({
            languages: (state) => state.theme2.settings.language,
            supportCountryAndLanguage: (state) =>
                state.settings.supportCountryAndLanguage,
        }),
    },
    methods: {
        isActiveLanguage(item) {
            return item?.supported_languages?.filter(
                (el) => el?.is_active || el?.is_default
            )
        },
    },
}
