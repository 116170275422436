
import { mapState, mapGetters } from 'vuex'
export default {
    name: 'NavBar',
    data: () => ({
        drawer: false,
        hostname: null,
        gameIndex: null,
    }),
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
            player: (state) => state.player.player,
        }),
        ...mapGetters({
            menubars: 'cms/menubars',
        }),
    },
    watch: {
        '$route.query.idx'(value) {
            this.gameIndex = value
        },
        menubars(value) {
            if (this.$route.query.idx) return
            this.gameIndex = value.length + 1
        },
    },
    mounted() {
        this.hostname = window.location.hostname
        if (this.$route?.query?.idx) {
            this.gameIndex = this.$route.query.idx
        }
    },
    methods: {
        onGameChange(index, title) {
            this.gameIndex = index
            this.$router.push(`/games?idx=${this.gameIndex}&title=${title}`)
        },
        getImageUri(icon) {
            if (!icon) return null
            const menubarIcon = ~~icon
            let imagePath = 'theme-3/mobile/games/v2/'
            // menubar_icon
            // 5 = slot
            // 3 = live casino
            // 8 = Virtual Sport
            // 1 = animal
            // 2 = finishing
            // 4 = Lottery
            // 6 = Sport
            // 7 = Table
            // 9 = Others
            if (menubarIcon === 1) imagePath += 'animal.png'
            else if (menubarIcon === 2) imagePath += 'fishing.png'
            else if (menubarIcon === 3) imagePath += 'casino.png'
            else if (menubarIcon === 4) imagePath += 'lottery.png'
            else if (menubarIcon === 5) imagePath += 'slot.png'
            else if (menubarIcon === 6) imagePath += 'sport.png'
            else if (menubarIcon === 7) imagePath += 'table-games.png'
            else if (menubarIcon === 8) imagePath += 'virtual.png'
            else if (menubarIcon === 9) imagePath += 'other-games.png'

            return imagePath
        },
    },
}
