import { render, staticRenderFns } from "./NavBar.vue?vue&type=template&id=2b8817a1&scoped=true"
import script from "./NavBar.vue?vue&type=script&lang=js"
export * from "./NavBar.vue?vue&type=script&lang=js"
import style0 from "./NavBar.vue?vue&type=style&index=0&id=2b8817a1&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8817a1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme4DesktopSwitchLanguage: require('/var/app/components/Theme4/Desktop/SwitchLanguage.vue').default,ThemeMode: require('/var/app/components/ThemeMode.vue').default})
