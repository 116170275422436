
import { mapState, mapGetters } from 'vuex'
import GlobalMixins from '~/mixins/globalMixins'
export default {
    name: 'Theme3NavBar',
    mixins: [GlobalMixins],
    data() {
        return {
            drawer: false,
            displayExchangeRate: false,
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
            languages: (state) => state.theme2.settings.language,
            player: (state) => state.player.player,
            downloadInterval: (state) => state.settings.downloadInterval,
            operationSetting: (state) => state.settings.operationSetting,
        }),
        ...mapGetters({
            headerLogo: 'cms/headerLogo',
            footerPartnerIcon: 'cms/footerPartnerIcon',
            footerArticle1: 'cms/footerArticle1',
            footerArticle2: 'cms/footerArticle2',
            footerArticle3: 'cms/footerArticle3',
            footerArticle4: 'cms/footerArticle4',
            footerTermAndCondition: 'cms/footerTermAndCondition',
        }),
        isPlayGamePage() {
            if (this.$route.path === '/play-game') return true
            return false
        },
        isHomePage() {
            return this.$route.path === '/'
        },
    },
    mounted() {
        if (this.$cookie.get('token')) {
            if (this.player.player_id) return
            this.$store.dispatch('player/profile')
            this.$store.dispatch('settings/toggleIsLogin', true)
            this.$store.dispatch('player/totalBalance')
        }
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
    methods: {
        onDisplayExchangeRate() {
            this.drawer = false
            this.displayExchangeRate = true
        },
        goToDetail(item) {
            if (item?.items?.length > 0) {
                const title = item?.title[`${this.systemLocale}-slug`]
                const slug =
                    item?.items?.length > 0
                        ? item?.items[0]?.title[`${this.systemLocale}-slug`]
                        : ''
                this.$router.push(
                    `/articles/${encodeURIComponent(
                        title
                    )}/${encodeURIComponent(slug)}`
                )
            } else {
                this.$router.push('/articles')
            }
        },
    },
}
