import { render, staticRenderFns } from "./MenuBar.vue?vue&type=template&id=a1a97dca"
import script from "./MenuBar.vue?vue&type=script&lang=js"
export * from "./MenuBar.vue?vue&type=script&lang=js"
import style0 from "./MenuBar.vue?vue&type=style&index=0&id=a1a97dca&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Theme2DesktopSubMenu: require('/var/app/components/Theme2/Desktop/SubMenu.vue').default})
